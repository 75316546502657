:local(.Linking) {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/images/amazon-music-bg.jpg"); }
  :local(.Linking) .amazon-logo {
    position: absolute;
    top: 2.5rem;
    left: 1.875rem;
    width: 12rem;
    height: 3.25rem;
    object-fit: contain;
    object-position: left top; }
    :local(.Linking) .amazon-logo--centered {
      position: relative;
      top: 0;
      left: 0;
      width: 14.4rem;
      height: 3.9rem;
      margin-bottom: 0.75rem;
      object-position: center; }
  :local(.Linking) .headline {
    font-family: 'AmazonFont He';
    font-weight: bold;
    font-size: 3.125rem; }
  :local(.Linking) .text-info {
    margin: 0.625rem auto;
    color: #fff;
    font-family: "AmazonFont", "Helvetica Neue", sans-serif;
    font-size: 1.875rem; }
  :local(.Linking) .text-link > a {
    color: #fff;
    text-decoration: none; }
  :local(.Linking) .code {
    width: 15.625rem;
    height: 5rem;
    background-color: rgba(0, 0, 0, 0.08);
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 0.9375rem; }
    :local(.Linking) .code > label {
      color: #fff;
      font-family: "AmazonFont", "Helvetica Neue", sans-serif;
      font-size: 3.125rem;
      font-weight: 500;
      line-height: 3.125rem;
      text-transform: uppercase;
      -webkit-user-select: text;
          -ms-user-select: text;
              user-select: text; }
