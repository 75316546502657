* {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.clicked-pulse {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-animation: pulse 800ms both;
  animation: pulse 800ms both;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 9; }

@keyframes pulse {
  from {
    opacity: 1;
    -webkit-transform: scale(0) translate3d(-50%, -50%, 0);
    transform: scale(0) translate3d(-50%, -50%, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(2.5) translate3d(-50%, -50%, 0);
    transform: scale(2.5) translate3d(-50%, -50%, 0); } }

@-webkit-keyframes pulse {
  from {
    opacity: 1;
    -webkit-transform: scale(0) translate3d(-50%, -50%, 0);
    transform: scale(0) translate3d(-50%, -50%, 0); }
  to {
    opacity: 0;
    -webkit-transform: scale(2.5) translate3d(-50%, -50%, 0);
    transform: scale(2.5) translate3d(-50%, -50%, 0); } }
